
import Vue from "vue";
import ProfileData from "@/components/Profile/ProfileData.vue";
import PostForm from "@/components/Posts/PostForm.vue";
import PostsContainer from "@/components/Posts/PostsContainer.vue";
import ActionPanel from "@/components/Profile/ActionPanel.vue";
import { mapGetters } from "vuex";
import ActionPanelLoading from "@/components/Profile/ActionPanelLoading.vue";
import PostsContainerLoading from "@/components/Posts/PostsContainerLoading.vue";
import ProfileDataLoading from "@/components/Profile/ProfileDataLoading.vue";
import ShareMentorcastModal from "@/components/Modals/ShareMentorcastModal.vue";
import LoadMore from "@/components/Partials/LoadMore.vue";
import PostFormLoading from "@/components/Posts/PostFormLoading.vue";
import DiscoverModal from "@/components/Modals/DiscoverModal.vue";

export default Vue.extend({
  name: "ProfilePage",
  components: {
    ProfileData,
    PostForm,
    PostsContainer,
    ActionPanel,
    ShareMentorcastModal,
    DiscoverModal,
    ActionPanelLoading,
    PostsContainerLoading,
    ProfileDataLoading,
    PostFormLoading,
    LoadMore
  },
  data() {
    const tabs = [
      (this as any).$t("profile.recentTab"),
      (this as any).$t("profile.postTab")
    ];
    return {
      data: [] as any,
      page: 1,
      load_more: false,
      loading: true,
      result: null,
      tabs,
      currentTab: (this as any).$t("profile.recentTab")
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile",
      moduleSettings: "helper/getModuleSettings",
      recentActivity: "profile/getRecentActivity"
    }),
    mentoringSessionStatus(): any {
      return this.moduleSettings["mentoring_session"] == 1;
    },
    mentorcastStatus(): any {
      return this.moduleSettings["mentorcast"] == 1;
    },
    mentoringProgramStatus(): any {
      return this.moduleSettings["mentoring_program"] == 1;
    },
    mentorLibraryStatus(): any {
      return this.moduleSettings["mentor_library"] == 1;
    },
    feedbackEngineStatus(): any {
      return this.moduleSettings["feedback_engine"] == 1;
    },
    mentorCommunityStatus(): any {
      return this.moduleSettings["mentor_community"] == 1;
    },
    sessionCertificateStatus(): any {
      return this.moduleSettings["session_certificate"] == 1;
    }
  },
  watch: {
    "$store.state.likeable.likeableData": function(data) {
      //@ts-ignore
      this.result = this.data.findIndex(i => i.recentable.id === data.id);

      // console.log(this.data, data);

      if (this.result != "-1") {
        //@ts-ignore
        this.data[this.result].recentable.like_counts.clap =
          data.like_counts.clap;

        //@ts-ignore
        this.data[this.result].recentable.like_counts.disagree =
          data.like_counts.disagree;

        //@ts-ignore
        this.data[this.result].recentable.like_counts.sceptic =
          data.like_counts.sceptic;
      }
    }
  },
  mounted(): void {
    this.$store.dispatch("helper/GET_MODULE_SETTINGS");
    const intendedRoute = localStorage.getItem("intendedRoute");
    if (intendedRoute) {
      localStorage.removeItem("intendedRoute");
      this.$router.push(intendedRoute);
    }
    if (this.mentorcastStatus) {
      this.tabs.push((this as any).$t("profile.mentorcastTab"));
    }

    this.getActivity();
  },
  methods: {
    setNewPost() {
      this.data = [];
      this.currentTab = this.tabs[0];
      this.getActivity();
    },
    onTab(tab: any) {
      this.data = [];
      this.currentTab = tab;
      this.getActivity();
    },
    onLoadMore() {
      this.page++;
      this.getActivity(this.page);
    },
    getActivity(page = 1) {
      this.loading = true;
      if (page == 1) {
        this.data = [];
      }
      this.$store
        .dispatch("profile/GET_RECENT_ACTIVITY", {
          page,
          tab: this.currentTab
        })
        .then(data => {
          this.loading = false;
          this.page = page;
          this.load_more = page < data.last_page;
          this.data.push(...data.data);
        });
    }
  }
});
