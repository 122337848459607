
import Vue from "vue";
import ClipperModal from "@/components/ClipperModal.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "PostForm",
  components: {
    ClipperModal
  },
  props: {
    isMentor: Boolean,
    profile: Object
  },
  data() {
    return {
      maxPostChars: 1000,
      url: "",
      data: {
        image: "",
        description: "",
        privacy_type: "All"
      },
      submitBtnLoading: false,
      buttons: [
        {
          name: (this as any).$t("post.postButtonsPhoto"),
          icon: "picture",
          action: () => {
            this.$bvModal.show("clipper");
          }
        }
        // {
        //   name: "Video",
        //   icon: "video",
        //   action: () => console.log("Video was clicked!")
        // },
        // {
        //   name: "Article",
        //   icon: "article",
        //   action: () => console.log("Article was clicked!")
        // }
        /*{
          name: "Mentorcast",
          icon: "scene",
          action: () => {
            this.$bvModal.show("share-mentorcast");
          }
        }*/
      ]
    };
  },
  computed: {
    mentoringSessionStatus(): any {
      return this.moduleSettings["mentoring_session"] == 1;
    },
    mentorcastStatus(): any {
      return this.moduleSettings["mentorcast"] == 1;
    },
    mentoringProgramStatus(): any {
      return this.moduleSettings["mentoring_program"] == 1;
    },
    mentorLibraryStatus(): any {
      return this.moduleSettings["mentor_library"] == 1;
    },
    feedbackEngineStatus(): any {
      return this.moduleSettings["feedback_engine"] == 1;
    },
    mentorCommunityStatus(): any {
      return this.moduleSettings["mentor_community"] == 1;
    },
    sessionCertificateStatus(): any {
      return this.moduleSettings["session_certificate"] == 1;
    },
    ...mapGetters({
      moduleSettings: "helper/getModuleSettings"
    })
  },
  mounted(): void {
    this.$store.dispatch("helper/GET_MODULE_SETTINGS");
    if (this.mentorcastStatus) {
      this.buttons.push({
        name: (this as any).$t("post.postButtonsMentorcast"),
        icon: "scene",
        action: () => {
          this.$bvModal.show("share-mentorcast");
        }
      });
    }
    // if (this.isMentor) {
    //   this.buttons.push({
    //     name: "Mentorcast",
    //     icon: "scene",
    //     action: () => console.log("Mentorcast was clicked!")
    //   });
    // }
  },
  methods: {
    setTempFile(data: any) {
      this.url = data.url;
      this.data.image = data.temp;
    },
    onPost() {
      this.submitBtnLoading = true;
      this.$store
        .dispatch("post/CREATE_POST", this.data)
        .then(data => {
          this.submitBtnLoading = false;
          this.url = "";
          this.data.description = "";
          this.data.image = "";
          this.$emit("post", data);
        })
        .catch(error => {
          this.submitBtnLoading = false;
          if (error.response.status === 422) {
            (this as any).$alertify.error(error.response.data.errors[0]);
          }
        });
    }
  }
});
