
import Vue from "vue";
import MentorcastData from "@/components/Mentorcast/MentorcastData.vue";
import { mapGetters } from "vuex";
import Pagination from "@/components/Pagination.vue";

export default Vue.extend({
  name: "ShareMentorcastModal",
  components: {
    MentorcastData,
    Pagination
  },
  props: {
    profile: Object
  },
  data() {
    return {
      tabs: [
        {
          name: "My Mentorcasts",
          type: "Upcoming"
        },
        {
          name: "Participating",
          type: "Participating"
        }
      ],
      mentorcastCount: 0,
      activeTab: "Upcoming",
      mentorcast_id: "",
      page: 1,
      profileShare: true
    };
  },
  computed: {
    getFilterData(): any {
      return {
        [this.profile.type === "mentor" ? "is_mine" : "is_mentee"]: true,
        available: true,
        type: this.activeTab
      };
    },
    ...mapGetters({
      mentorcasts: "mentorcast/getMentorcasts"
    })
  },
  created() {
    this.getMentorcasts();
  },
  mounted() {
    if (this.profile.type == "mentee") {
      const index = this.tabs.findIndex((e: any) => e.type == "Upcoming");
      this.activeTab = "Participating";
      this.tabs.splice(index, 1);
    }
  },
  methods: {
    onTab(type: string) {
      this.activeTab = type;
      this.getMentorcasts();
    },
    getMentorcasts() {
      //@ts-ignore
      if (this.$refs["pagination"]) {
        //@ts-ignore
        this.$refs["pagination"].setPage(1);
      }
      this.$store.dispatch("mentorcast/GET_ALL_MENTORCAST", {
        data: {
          ...this.getFilterData
        }
      });
    },
    onShare() {
      if (!this.mentorcast_id) {
        (this as any).$alertify.error("Please select mentorcast first.");
        return;
      }
      this.$store
        .dispatch(
          "profile/SHARE_MENTORCAST_IN_RECENT_ACTIVITY",
          this.mentorcast_id
        )
        .then(() => {
          this.$bvModal.hide("share-mentorcast");
          (this as any).$alertify.success("Mentorcast shared successfully");
          this.$emit("update");
        });
    }
  }
});
