
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "ActionPanel",
  props: {
    isOwner: Boolean,
    userType: String,
    profile: Object
  },
  data() {
    return {
      // Profile actions buttons
      buttons: [] as any
    };
  },
  computed: {
    mentoringSessionStatus(): any {
      return this.moduleSettings["mentoring_session"] == 1;
    },
    mentorcastStatus(): any {
      return this.moduleSettings["mentorcast"] == 1;
    },
    mentoringProgramStatus(): any {
      return this.moduleSettings["mentoring_program"] == 1;
    },
    mentorLibraryStatus(): any {
      return this.moduleSettings["mentor_library"] == 1;
    },
    feedbackEngineStatus(): any {
      return this.moduleSettings["feedback_engine"] == 1;
    },
    mentorCommunityStatus(): any {
      return this.moduleSettings["mentor_community"] == 1;
    },
    sessionCertificateStatus(): any {
      return this.moduleSettings["session_certificate"] == 1;
    },
    ...mapGetters({
      moduleSettings: "helper/getModuleSettings"
    })
  },
  mounted(): void {
    this.$store.dispatch("helper/GET_MODULE_SETTINGS");
    // Display buttons if it is my profile mentor page
    if (this.userType === "mentor" && this.isOwner) {
      if (this.mentoringSessionStatus) {
        this.buttons.push({
          name: (this as any).$t("profile.actionPanelSessionBtn"),
          icon: "tea-table",
          action: () => this.$router.push({ path: "/sessions" })
        });
      }
      if (this.mentorcastStatus) {
        const trans = `${(this as any).$t("profile.myWord")} ${(this as any).$t(
          "profile.actionPanelMentorcastsBtn"
        )}`;
        this.buttons.push({
          name: trans,
          icon: "my-scene",
          action: () => this.$router.push({ path: `/mentorcasts` })
        });
      }
      if (this.mentorLibraryStatus) {
        const trans = `${(this as any).$t("profile.myWord")} ${(this as any).$t(
          "profile.actionPanelLibraryBtn"
        )}`;
        this.buttons.push({
          name: trans,
          icon: "my-library",
          action: () => this.$router.push({ path: "/my-library" })
        });
      }
      if (this.mentoringProgramStatus) {
        const trans = `${(this as any).$t("profile.myWord")} ${(this as any).$t(
          "profile.actionPanelProgramsBtn"
        )}`;
        this.buttons.push({
          name: trans,
          icon: "my-program",
          action: () => this.$router.push({ path: `/mentoring-programs` })
        });
      }
      // Display buttons if it is my profile mentoree page
    } else if (this.userType === "mentee" && this.isOwner) {
      if (this.mentoringSessionStatus) {
        this.buttons.push({
          name: (this as any).$t("profile.actionPanelSessionBtn"),
          icon: "tea-table",
          action: () => this.$router.push({ path: "/sessions" })
        });
      }
      if (this.mentorcastStatus) {
        this.buttons.push({
          name: (this as any).$t("profile.actionPanelMentorcastsBtn"),
          icon: "my-scene",
          action: () => this.$router.push({ path: `/mentorcasts` })
        });
      }
      if (this.mentorLibraryStatus) {
        this.buttons.push({
          name: (this as any).$t("profile.actionPanelLibraryBtn"),
          icon: "my-library",
          action: () => this.$router.push({ path: `/my-library` })
        });
      }
      if (this.mentoringProgramStatus) {
        this.buttons.push({
          name: (this as any).$t("profile.actionPanelProgramsBtn"),
          icon: "my-program",
          action: () => this.$router.push({ path: `/mentoring-programs` })
        });
      }
    } else {
      if (this.mentoringSessionStatus) {
        this.buttons.push({
          name: (this as any).$t("profile.actionPanelSessionBtn"),
          icon: "tea-table",
          action: () =>
            this.$router.push({
              path: `/mentors/${this.$route.params.id}/agenda`
            })
        });
      }
      if (this.mentorcastStatus) {
        this.buttons.push({
          name: (this as any).$t("profile.actionPanelMentorcastsBtn"),
          icon: "my-scene",
          action: () =>
            this.$router.push({
              path: `/mentors/${this.$route.params.id}/mentorcasts`
            })
        });
      }
      if (this.mentorLibraryStatus) {
        this.buttons.push({
          name: (this as any).$t("profile.actionPanelLibraryBtn"),
          icon: "my-library",
          action: () =>
            this.$router.push({
              path: `/mentors/${this.$route.params.id}/library`
            })
        });
      }
      if (this.mentoringProgramStatus) {
        this.buttons.push({
          name: (this as any).$t("profile.actionPanelMentoringBtn"),
          icon: "my-program",
          action: () => this.$bvModal.show("request-program-mentoring")
        });
      }
    }
  }
});
